.notify {
  label {
    padding-top: 20px;
  }

  .inputs {
    p {
      color: red;
    }
  }

  .buttions {
    display: flex;
    justify-content: center;

    button {
      width: 80%;
      margin-top: 30px;
      text-align: center;
    }
  }
}

.search_user {
  background-color: rgb(207, 233, 255);

  li {
    padding-top: 4px;
    padding-left: 10px;
    list-style-type: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    :hover {
      background-color: bisque;
    }
  }
}

.notifications {
  padding-top: 50px;
  padding-bottom: 50px;
  h3 {
    font-size: 25px;
  }
  p {
    color: rgb(95, 144, 223);
    font-size: 14px;
  }
  small {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 1px;
  }

  .loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
  }

  .cards_notification {
    background-color: #fff;
    width: 100%;
    padding: 10px;
    height: 100%;
    margin-top: 20px;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    gap: 20px;
    align-items: center;

    img {
      border-radius: 100px;
    }

    span {
      line-height: 30px;
      font-size: 17px;
      color: black;
    }

    p {
      margin: 2px;
      font-size: 15px;
      color: gray;
    }

    small {
      color: gray;
    }
  }
}

.title_notify {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  p {
    color: rgb(22, 85, 144);
    font-weight: 500;
    cursor: pointer;
  }
}

.cards_notification {
  cursor: pointer;
}

.paginations_notify {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.userDetails{
  display: flex;
  //   flex-direction: row;
}
.user_det {
  display: flex;
  align-items: center;
  gap: 42px;
  width: 100%;
}