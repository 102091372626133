label {
  font-size: 14px;
}
.form-control {
  font-size: 15px;
}
p {
  font-size: 18px;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: transparent;
  outline: 0;
  box-shadow: none;
}
.add {
  cursor: pointer;
}
.fade.modal.show {
  z-index: 100000007;
}
.error {
  font-size: 13px;
}
.Toastify {
  font-size: 16px !important;
}

select {
  font-size: 16px;
}
.group_det {
  display: flex;
  gap: 40px;
}
.group_det p {
  font-size: 18px;
  width: 100%;
  font-weight: 500;
}
.group_det span {
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}
div {
  cursor: pointer;
}
.group-member p {
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #a29494;
  display: flex;
  justify-content: space-between;
}
.group-member p span {
  font-size: 15px;
  font-weight: 500;
  background-color: #f50707;
  color: #fff;
  padding: 6px 20px;
  border-radius: 10px;
  margin-bottom: 2px;
}
.group-member ul li {
  list-style-type: none;
}
.group-member ul {
  padding-left: 0px;
}

.detail_mem {
  display: flex;
  align-items: center;
  gap: 20px;
}
.detail_mem img {
  border-radius: 50px;
}
li .detail_mem {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.css-z2hyoe-ItemComponent.react-dropdown-select-item-selected {
  background-color: #f50707 !important;
  color: #fff !important;
  border-radius: 5px !important;
}
.css-9ma7ck-OptionComponent {
  background-color: #f50707 !important;
  color: #fff !important;
}
.chops {
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}
.chops p {
  border: 1px solid #f50707;
  color: #f50707;
  padding: 4px 20px;
  border-radius: 10px;
}
.chops p a {
  text-decoration: underline !important;
  color: #fff;
}
.chops span {
  font-size: 15px;
  color: gray;
}
.selected {
  background-color: #f50707 !important;
  color: #fff !important;
}
.select_user {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.select_user_main {
  margin-top: 10px;
}
.select_user_main span {
  font-size: 14px;
}
.select_user p {
  background-color: #7f7878;
  color: #fff;
  padding: 8px 18px;
  border-radius: 10px;
  font-size: 12px;
}
.close_image {
  width: 10px;
  height: 10px;
  filter: invert();
  position: relative;
  transform: translateY(-10px);
  right: -10px;
}
.css-af8kz-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  box-shadow: 0rem 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, -0.9),
    0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05) !important;
}
