.event-members-item {
    color: gray;
}

.event-members-item:hover {
    color: blue;
}
.members-block {
    display: flex;
    gap: 10px;
    /* justify-content: center; */
    align-items: center;
}
.members-block span {
    font-size: 10px;
    color: gray;
}
.members-block label {
    font-size: 20px;
}