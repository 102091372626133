.css-1jqq78o-placeholder {
  font-size: 15px;
}
.input#react-select-3-input {
  font-size: 15px !important;
  border: none;
}
.css-13cymwt-control {
  border-style: none;
  border-width: 0px;
  box-sizing: border-box;
}
.form-control.css-b62m3t-container {
  padding: 0px;
}
.css-99a237,
.css-1x3v3vy {
  background: #fb1414 !important;
}
.css-17pt5qv {
  justify-content: space-between;
}
.css-17pt5qv span {
  width: 100%;
}
.css-t3ipsp-control {
  border-width: 0px !important;
  border-color: #fff !important;
}
canvas.vanta-canvas {
  color: white;
  padding: 0px !important;
  font-size: 24px;
  min-height: 60vh;
  position: absolute;
}
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-1c7e7jm-MuiButtonBase-root-MuiMenuItem-root:hover {
  background: #eeafb0 !important;
}
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-1c7e7jm-MuiButtonBase-root-MuiMenuItem-root {
  background: #e7e0e0 !important;
}
.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-af8kz-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  padding: 15px !important;
}
.notification_list {
  margin-bottom: 2px;
  background-color: #fb1414 !important;
  color: #fff;
  border-radius: 20px;
  padding: 2px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}
