
.details_pro {
  display: flex;
  justify-content: space-between;
  gap: 200px;

}
.details_pro p{
    width: 100%;
    font-weight: 500;
  
}
.details_pro span{
    font-size: 15px;
    width: 100%;

}
