label{
    font-size: 14px;
}
.form-control{
    font-size: 15px;
}
p{font-size: 18px;}
.form-control:focus {
    color: var(--bs-body-color);
    background-color: transparent;
    outline: 0;
    box-shadow: none;
}
.add{
    cursor: pointer;
}
.fade.modal.show {
    z-index: 100000007;
}
.error{
    font-size: 13px;
}
.Toastify{
    font-size: 16px !important;
}

select{
    font-size: 16px;
}
.group_det{
display: flex;
gap: 40px;
}
.group_det p {
    font-size: 18px;
    width: 100%;
    font-weight: 500;
}
.group_det span {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
}
div{
cursor:  pointer;
}
.group-member p{
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #a29494;
    
}
.group-member ul li{
    list-style-type: none;
}
.group-member ul{
    padding-left: 0px;
}

.detail_mem{
    display: flex;
    align-items: center;
    gap: 20px;
}
.detail_mem img{
    border-radius: 50px;
}
li .detail_mem{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
}