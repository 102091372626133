.post-image {
    height: 80px;
    width: 80px;
    max-height: 80px;
    max-width: 80px;
    object-fit: cover;
}

a {
    color: black;
}